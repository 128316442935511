import React, { Component } from 'react';
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link';

import { closeBurgerMenu } from './../redux/UI';
import { connect } from 'react-redux';
// import { SignupForNewsletter } from './../components';

import IconMedium from '../assets/icons/medium.svg'
import IconTwitter from '../assets/icons/twitter.svg'
import IconGithub from '../assets/icons/github.svg'
import IconTelegram from '../assets/icons/telegram-footer.svg'
import IconLinkedIn from '../assets/icons/linkedin.svg'
// import IconDiscord from '../assets/icons/discord.svg'
// import IconInstagram from '../assets/icons/instagram.svg'
// import IconFacebook from '../assets/icons/facebook.svg'

const Wrapper = styled.div`
  width: 100%;
  background-color: #000;
  display: flex;
  height: 349px;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px 0 75px 0;

  @media screen and (max-width: 780px) {
    flex-direction: row;
    height: auto;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
  }

  @media screen and (max-width: 359px) {
    flex-direction: column;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;

  @media screen and (max-width: 780px) {
    align-items: center;
    justify-content: flex-start;
    margin: 25px 0;
    width: 50%;
    text-align: center;
    height: auto;
  }
`;

const Header = styled.h6`
  font-size: 14px;
  color: white;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.03px;
  margin: 0 0 34px 0;
  font-family: 'Roboto Mono';

  // @TODO
  /*@media screen and (max-width: 780px) {
		position: relative;
		left: -9px;

		&:nth-child(1n) {
			position: relative;
			left: 0;
		}

	}*/
`;

const LinkGroupLeft = styled.ul`
  display: flex;
  flex-direction: column;
  font-family: 'Rubik';
  margin: 0 0;
  padding: 0 0 0 0;
  @media screen and (max-width: 780px) {
    align-items: center;
    justify-content: center;
  }
`;

const LinkGroup = styled.ul`
  display: flex;
  flex-direction: column;
  font-family: 'Rubik';
  margin: 0 0;
  padding: 0 0 0 27px;
  @media screen and (max-width: 780px) {
    align-items: center;
    justify-content: center;
  }
`;

const ExternalLink = styled.a`
  padding-bottom: 14px;
  color: white;
  opacity: 0.5;
  line-height: 17px;
  letter-spacing: 0.03px;
  position: relative;
  font-size: 14px;

  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-image: url(${props => props.icon});
    background-size: contain;
    background-position: center;
    z-index: 99;
    left: -27px;
    top: 1px;
    background-repeat: no-repeat;
  }

  &:after {
    position: absolute;
    left: 50%;
    content: '';
    height: 3px;
    background: #bc00ff;
    transition: all 0.2s linear;
    width: 0;
    bottom: -5px;
  }
  &:hover {
    color: white;
    opacity: 1;
    transition: all 175ms ease-in-out;
    &:after {
      width: 100%;
      left: 0;
      bottom: 5px;
    }
  }
`;

const NavItem = styled(HashLink).attrs(props => ({
  className: 'nav-item-active',
}))`
  padding-bottom: 14px;
  color: white;
  opacity: 0.5;
  line-height: 17px;
  letter-spacing: 0.03px;
  position: relative;
  font-size: 14px;

  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-image: url(${props => props.icon});
    background-size: contain;
    background-position: center;
    z-index: 99;
    left: -27px;
    top: 1px;
    background-repeat: no-repeat;
  }

  &:after {
    position: absolute;
    left: 50%;
    content: '';
    height: 3px;
    background: #bc00ff;
    transition: all 0.2s linear;
    width: 0;
    bottom: -5px;
  }
  &:hover {
    color: white;
    opacity: 1;
    transition: all 175ms ease-in-out;
    &:after {
      width: 100%;
      left: 0;
      bottom: 5px;
    }
  }
`;

const TradeMark = styled.div`
  padding-bottom: 14px;
  color: white;
  opacity: 0.5;
  line-height: 17px;
  letter-spacing: 0.03px;
  font-size: 14px;
`;

class Footer extends Component {
  render() {
    const { closeBurgerMenu } = this.props;

    return (
      <Wrapper>
        <Column onClick={() => closeBurgerMenu()}>
          <Header>Covee</Header>
          <LinkGroupLeft>
            <ExternalLink
              href="https://covee.network"
              target=""
            >
              Home
            </ExternalLink>
            {/*
            <NavItem to="/">Home</NavItem>
              <NavItem to="/about#jobs">Jobs</NavItem>
              <NavItem to="/about#office">Office</NavItem>
            */}
            {/*
            <ExternalLink
              href="https://discordapp.com/channels/473811606837526541/473811606837526559"
              target="_blank"
            >
              Dev Portal
            </ExternalLink>
            */}
            <ExternalLink
              href="covee-legal.pdf"
              target="_blank"
            >
              Legal
            </ExternalLink>
            {/*
              <NavItem to="/whitepaper">Whitepaper</NavItem>
              <NavItem to="/wiki">Wiki</NavItem>
              <NavItem to="/imprint">Imprint</NavItem>
            */}
          </LinkGroupLeft>
        </Column>
        {/*
          <Column onClick={() => closeBurgerMenu()}>
          <Header>About</Header>
          <LinkGroup>
            <NavItem to="/vision">Vision</NavItem>
            <NavItem to="/about#team">Team</NavItem>
            <NavItem to="/about#investors" hash="investors">
              Investors
            </NavItem>
            <NavItem to="/about#experts">Experts</NavItem>
            <NavItem to="/about#advisors">Advisors</NavItem>
            <NavItem to="/about#partners">Partners</NavItem>
            <NavItem to="/#whitepaper">White Paper</NavItem>
          </LinkGroup>
          </Column>
        */}
        <Column>
          <Header>Community</Header>
          <LinkGroup>
            <ExternalLink
              href="https://medium.com/covee-network"
              target="_blank"
              icon={`${IconMedium}`}
            >
              Medium
            </ExternalLink>
            <ExternalLink
              href="https://twitter.com/coveenetwork"
              target="_blank"
              icon={`${IconTwitter}`}
            >
              Twitter
            </ExternalLink>
            <ExternalLink
              href="https://github.com/coveenetwork"
              target="_blank"
              icon={`${IconGithub}`}
            >
              Github
            </ExternalLink>
            <ExternalLink
              href="https://t.me/coveenetwork"
              target="_blank"
              icon={`${IconTelegram}`}
            >
              Telegram
            </ExternalLink>
            <ExternalLink
              href="https://www.linkedin.com/company/covee-network"
              target="_blank"
              icon={`${IconLinkedIn}`}
            >
              LinkedIn
            </ExternalLink>
            {/*
            <ExternalLink
              href="https://discordapp.com/channels/473811606837526541/473811606837526559"
              target="_blank"
              icon={`${IconDiscord}`}
            >
              Discord
            </ExternalLink>
            */}
            {/*
            <ExternalLink
              href="https://www.instagram.com/coveenetwork"
              target="_blank"
              icon={`${IconInstagram}`}
            >
              Instagram
            </ExternalLink>
            */}
            {/*
            <ExternalLink
              href="https://www.facebook.com/coveenetwork"
              target="_blank"
              icon={`${IconFacebook}`}
            >
              Facebook
            </ExternalLink>
            */}
          </LinkGroup>
        </Column>
        {/*
          <Column>
            <Header>Newsletter</Header>
            <LinkGroup>
              <SignupForNewsletter
                type="newsletter"
                margin="10px 0"
                formdisplay="flex"
                formdirection="column"
                padding="10px 66px"
                buttonWidth="100%"
                paddingTablet="12px 56px"
                paddingMobile="12px 56px"
                backgroundColor="transparent"
                buttonBorder="solid 1px white"
                buttonColor="white"
                mailchimpUrl="https://network.us17.list-manage.com/subscribe/post?u=ba8e8ae977e232007b05c4f52&id=dc9d82fa6a"
              >
                Sign-up
              </SignupForNewsletter>
            </LinkGroup>
          </Column>
        */}
        <Column>
          <Header>Company</Header>
          <TradeMark>© 2018-2023 Covee Network AG</TradeMark>
          <TradeMark>Seilergraben 53</TradeMark>
          <TradeMark>8001 Zürich</TradeMark>
          <TradeMark>Switzerland</TradeMark>
        </Column>
      </Wrapper>
    );
  }
}

export default connect(null, { closeBurgerMenu })(Footer);
