import React from 'react';
import styled from 'styled-components'

const Button = styled.button`
  pointer-events: ${props => (props.disable ? 'none' : 'auto')};
  width: ${props => props.buttonWidth};
  font-family: 'Rubik';
  background-color: ${props => {
    if (props.success) {
      return '#00cc7a';
    } else if (props.failure) {
      return 'red';
    } else {
      return props.backgroundColor;
    }
  }};
  color: ${props => {
    if (props.success || props.failure) {
      return 'white';
    } else {
      return props.color;
    }
  }};
  border-radius: 3px;
  padding: ${props => props.padding};
  font-weight: 400;
  position: relative;
  cursor: pointer;
  transition: all 225ms ease-in-out;
  font-size: ${props => props.fontSize};
  border: ${props => props.border};
  opacity: ${props => props.opacity};
  line-height: 13px;
  letter-spacing: 0.1px;
  position: relative;
  transition: all 225ms ease-in-out;

  &:hover {
    transition: all 225ms ease-in-out;
    transform: scale(1.03);
  }

  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    background-image: url(${props => props.iconSrc});
    left: ${props => props.iconLeft};
    top: ${props => props.iconTop};
  }

  &:hover {
    transition: all 225ms ease-in-out;
  }

  @media screen and (max-width: 778px) {
    margin: ${props => props.marginTablet};
    padding: ${props => props.paddingTablet};
  }

  @media screen and (max-width: 520px) {
    padding: ${props => props.paddingMobile};

    &:after {
      top: ${props => props.iconTopMobile};
    }
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  width: ${props => props.width};
  margin: ${props => props.margin};

  @media screen and (max-width: 620px) {
    margin: ${props => props.tabletWrapperMargin};
    width: ${props => props.tabletWrapperWidth};
  }
`;

export default ({
  children,
  href,
  backgroundColor,
  color,
  border,
  fontSize,
  padding,
  width,
  margin,
  opacity,
  iconSrc,
  iconTop,
  iconLeft,
  iconTopMobile,
  buttonWidth,
  paddingMobile,
  paddingTablet,
  marginTablet,
  tabletWrapperMargin,
  tabletWrapperWidth,
  onClick,
  success,
  failure,
  disabled,
}) => {
  return (
    <Wrapper
      width={width}
      margin={margin}
      tabletWrapperMargin={tabletWrapperMargin}
    >
      <Button
        disable={disabled}
        success={success}
        failure={failure}
        buttonWidth={buttonWidth}
        iconSrc={iconSrc}
        color={color}
        fontSize={fontSize}
        border={border}
        backgroundColor={backgroundColor}
        padding={padding}
        opacity={opacity}
        iconTop={iconTop}
        iconLeft={iconLeft}
        paddingMobile={paddingMobile}
        paddingTablet={paddingTablet}
        marginTablet={marginTablet}
        onClick={onClick}
        iconTopMobile={iconTopMobile}
      >
        {children}
      </Button>
    </Wrapper>
  );
};
