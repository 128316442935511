import styled from 'styled-components'

const PartnersSection = styled.div`
  display: flex;
  height: 70px;
  width: 100vw;
  background-color: #000;
  justify-content: start;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  z-index: -5;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

const PartnerLogo = styled.img`
  filter: grayscale(100%) contrast(0%);
  src: url(${props => props.src});
  height: ${props => (props.height ? props.height : '40px')};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  object-fit: ${p => p.objectFit};
  max-width: 150px;
  transition: all 225ms ease-in-out;
  width: ${props => props.width};
  opacity: 0.84;
  &:hover {
    transform: scale(1.15);
    transition: all 225ms ease-in-out;
    opacity: 1;
  }
`;

const FeaturedLogo = styled.div`
  width: 200px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: contain;
  color: white;
  text-transform: capitalize;
  text-align: center;
`;

export { PartnersSection, PartnerLogo, FeaturedLogo };
