import React, { Component } from 'react';
import styled from 'styled-components'
import ModalVideo from 'react-modal-video';
import { connect } from 'react-redux';
import { toggleVideo } from './../redux/UI';

import { Button } from './index';

import ImgPlay from '../assets/icons/play.svg';

const Wrapper = styled.div`
	position: absolute;
	width: auto;
	height: auto;
	text-align: left;
	padding: 16px 31px;
	left: 130px;
	bottom: 155px;
	background-color: rgba(0, 0, 0, 0.85);
	box-shadow: rgba(0, 0, 0, 0.75) 0px 16px 24px 0px;
	border-radius: 3.5px;

	/* TV */
	@media screen and (min-width: 2800px) {
		width: 1250px;
	}

	/* PC */
	@media screen and (min-width: 2400px) {
		left: 200px;
		width: 750px;
		height: 500px;
	}

	@media screen and (min-width: 1749px) {
		left: 100px;
		width: auto;
		height: auto;
		padding: 16px 31px;
		bottom: 150px;
	}

	@media screen and (max-width: 1040px) {
		left: 175px;
		width: auto;
		height: auto;
		padding: 16px 31px;
		bottom: 150px;
	}

	@media screen and (max-width: 725px) {
		left: 0;
		width: auto;
		height: auto;
		padding: 15px
		bottom: 0;
	}

	@media screen and (max-width: 725px) {
		padding: 10px
	}
`;

const Header = styled.h1`
  font-size: 33px;
  color: #fff;
  line-height: 47px;
  letter-spacing: 0.36px;
  font-weight: 400;
  text-shadow: 0px 0px 3px #000, -1px -1px #000, 1px 1px #000;
  font-family: 'Rubik';
  margin: 0;

  @media screen and (min-width: 2440px) {
    font-size: 54px;
    line-height: 65px;
  }

  @media screen and (min-width: 480px) {
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    width: 440px;
    margin: 0 0 10px 0;
  }

  @media screen and (max-width: 479px) {
    font-size: 22px;
    width: 300px;
    line-height: 27px;
    margin: 0 0 10px 0;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const LearnMoreText = styled.p`
  font-weight: 400;
  font-size: 15px;
  margin: 0 0 0 30px;
  color: #fff;
  transition: all 200ms ease-in-out;

  &:hover {
    color: #fefefe;
    transition: all 200ms ease-in-out;
  }
`;

class LandingText extends Component {
  render() {
    const { toggleVideo, ui } = this.props;

    return (
      <Wrapper>
        <Header>
          Join knowledge work teams without the boundaries of traditional organizations
        </Header>
        <ButtonWrapper>
          <ModalVideo
            channel="youtube"
            videoId="fFa8p4qiuek"
            onClose={() => toggleVideo()}
            isOpen={ui.isVideoOpen}
          />
          <Button
            padding="14px 30px 14px 42.5px"
            color="white"
            backgroundColor="#411B4E"
            border="none"
            iconSrc={ImgPlay}
            iconTop="16px"
            iconLeft="19px"
            iconTopMobile="20px"
            paddingMobile="18px 30px 18px 42.5px"
            onClick={() => toggleVideo()}
          >watch video</Button>
          <a href="/#whitepaper">
            <LearnMoreText>Covee Protocol White Paper</LearnMoreText>
          </a>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ ui }) => ({ ui });

export default connect(mapStateToProps, { toggleVideo })(LandingText);
