import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom';

const Button = styled.button`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  border-radius: 3px;
  border: none;
  padding: ${p => (p.padding ? p.padding : '14px 30px 14px 42.5px')};
  font-weight: 100;
  position: relative;
  cursor: pointer;
  transition: all 225ms ease-in-out;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 900;
  width: ${p => p.width};
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    background-image: url(${props => props.iconSrc});
    left: 20px;
    top: 18px;
  }

  &:hover {
    transition: all 225ms ease-in-out;
    transform: scale(1.1);
  }
`;

export default ({
  children,
  to,
  backgroundColor,
  color,
  iconSrc,
  onClick,
  width,
  padding,
}) => {
  return (
    <Button
      iconSrc={iconSrc}
      color={color}
      backgroundColor={backgroundColor}
      onClick={onClick}
      width={width}
      padding={padding}
    >
      {children}
    </Button>
  );
};
