import React from 'react';
import styled from 'styled-components'
import { Fade } from 'react-reveal';
import { Button } from './index';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10%;
  @media screen and (max-width: 1440px) {
    padding: 0 10px;
  }

  @media screen and (max-width: 1050px) {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
`;

const HalfColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: left;
  margin: 10px auto;

  @media screen and (max-width: 1050px) {
    width: 100%;
    align-items: center;
    margin: 30px 0;
  }
`;

const InfoGraphic = styled.div`
  background-image: url(${props => props.imageSrc});
  width: 100%;
  margin: 25px auto;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: 1440px) {
    width: 441px;
    height: 266px;
    margin: 10px 0;
  }

  @media screen and (max-width: 1050px) {
    height: 330px;
    margin: 0;
    width: 325px;
  }

  @media screen and (max-width: 480px) {
    width: 100vw;
    height: 175px;
    background-position: center;
  }
`;

const Title = styled.h4`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.04px;
  margin: 45px 0 10px 0;
  font-weight: 400;
  color: #000;
`;

const Paragraph = styled.p`
  font-size: 13px;
  opacity: 0.6;
  line-height: 28px;
  letter-spacing: 0.03px;
  width: 441px;
  color: #000;

  @media screen and (max-width: 480px) {
    width: 300px;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default ({
  firstTitle,
  firstInfoGraphicSrc,
  secondInfoGraphicSrc,
  firstParagraph,
  secondTitle,
  secondParagraph,
}) => {
  return (
    <Wrapper>
      <HalfColumnWrapper>
        <Fade>
          <InfoGraphic imageSrc={firstInfoGraphicSrc} />
          <TextWrapper>
            <Title>{firstTitle}</Title>
            <Paragraph>{firstParagraph}</Paragraph>
            <a
              href="https://medium.com/covee-network/creating-scalable-peer-to-peer-trust-and-replacing-firms-with-smart-contracts-a138890812aa"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                color="#000"
                backgroundColor="#fff"
                border="solid 1px #e5e5e5"
                fontSize="13px"
                padding={'8px 19px'}
              >
                Read more
              </Button>
            </a>
          </TextWrapper>
        </Fade>
      </HalfColumnWrapper>
      <HalfColumnWrapper>
        <Fade>
          <InfoGraphic imageSrc={secondInfoGraphicSrc} />
          <TextWrapper>
            <Title>{secondTitle}</Title>
            <Paragraph>{secondParagraph}</Paragraph>
            <a
              href="https://medium.com/covee-network/creating-scalable-peer-to-peer-trust-and-replacing-firms-with-smart-contracts-a138890812aa"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                color="#000"
                backgroundColor="#fff"
                border="solid 1px #e5e5e5"
                fontSize="13px"
                padding={'8px 19px'}
              >
                Read more
              </Button>
            </a>
          </TextWrapper>
        </Fade>
      </HalfColumnWrapper>
    </Wrapper>
  );
};
