import React, { PureComponent } from 'react';
import styled, { keyframes } from 'styled-components'
import { Fade } from 'react-reveal';

import HowCoveeWorks from '../../assets/images/howcoveeworks.png';
import InfoGraphic2 from '../../assets/images/infograph2.png';
import StakingInfographic from '../../assets/images/infograph_staking.png';
import RewardDistributionInfographic from '../../assets/images/infograph_reward_distribution.png';

import {
  PageTitleWithPurpleTyped,
  PageSubTitle,
  InfoGraphics,
  PageTitle,
  TwoColumnLayout,
} from './../../components';

const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  background: #fff;
  position: relative;
  padding-top: 75px;
  text-align: center;
  padding-bottom: 35px;
`;

const Jobs = styled.div`
  background-image: url(${InfoGraphic2});
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
  margin: 0 0 -75px 0;

  @media screen and (max-width: 840px) {
    background-size: cover;
    height: 50px;
    margin: 0 0 50px 0;
  }

  @media screen and (max-width: 425px) {
    margin: -20px 0 50px 0;
  }
`;

export default class InfoView extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Fade cascade top>
          <PageTitleWithPurpleTyped />
          <PageSubTitle>
            Covee helps smart people find teammates - and matches them with
            rewarding projects.
          </PageSubTitle>
          <InfoGraphics imageSrc={HowCoveeWorks} />
        </Fade>
        <Jobs />
        <Fade top cascade>
          <PageTitle>The new way to build trust</PageTitle>
          <PageSubTitle>
            Don't trust your boss, trust your team and the smart contract to
            receive fair rewards.
          </PageSubTitle>
        </Fade>
        <TwoColumnLayout
          firstInfoGraphicSrc={StakingInfographic}
          firstTitle="Project Staking"
          firstParagraph="Have a stake in your projects. Just like a deposit for a rental
                          apartment, this project application requirement will help everybody on
                          a project to be fully engaged and have a higher mutual level of trust."
          secondInfoGraphicSrc={RewardDistributionInfographic}
          secondTitle="Your fair share"
          secondParagraph="The project reward is based on what teammates vote for how they estimate you've performed. So you will always be motivated to make it happen and your blood, sweat and tears always pay off."
        />
      </Wrapper>
    );
  }
}
