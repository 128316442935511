import { createStore, applyMiddleware } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import RootReducer from './RootReducer';
import thunkMiddleware from 'redux-thunk';

export const configureStore = (initialState = {}, browserWindow = null) => {
  let createStoreWithDevTools = createStore;
  if (browserWindow) {
    createStoreWithDevTools = browserWindow.__REDUX_DEVTOOLS_EXTENSION__
      ? browserWindow.__REDUX_DEVTOOLS_EXTENSION__({ latency: 0 })(createStore)
      : createStoreWithDevTools;
  }
  const createStoreWithMiddleware = applyMiddleware(
    thunkMiddleware,
    apiMiddleware
  )(createStoreWithDevTools);

  const store = createStoreWithMiddleware(RootReducer, initialState);
  return store;
};
