import React from 'react';
import styled from 'styled-components'
import { Fade } from 'react-reveal';

import TrustInfographMobile from '../assets/images/trust_infograph_mobile.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Image = styled.div`
  background-image: url(${props => props.imageSrc});
  width: 1111px;
  margin: 25px auto;
  height: 447px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;

  @media screen and (max-width: 1225px) {
    max-width: 780px;
    background-size: contain;
  }

  @media screen and (max-width: 785px) {
    background-image: url(${TrustInfographMobile});
    background-size: contain;
    width: auto;
    height: 1080px;
  }
`;

export default ({ imageSrc }) => {
  return (
    <Fade>
      <Wrapper>
        <Image imageSrc={imageSrc} />
      </Wrapper>
    </Fade>
  );
};
