import React, { Component } from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Fade } from 'react-reveal';

import { toggleBurgerMenu } from './../redux/UI';

import { Footer } from './index';

import CoveeLogoText from '../assets/coveelogotext.svg';

const Wrapper = styled.nav`
  transition: all 250ms ease-in-out;
  position: fixed;
  z-index: 10;
  padding: 11px 0;
  background: #000;
  width: 100vw;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  height: 0;
  opacity: 0;
  top: 0;

  @media screen and (max-width: 840px) {
    height: ${props => (props.open ? '0' : '40px')};
    opacity: ${props => (props.open ? '0' : '1')};
  }
`;

const LogoWrapper = styled.div`
  width: 200px;
  display: inline-block;

  @media screen and (max-width: 359px) {
    width: 150px;
  }
`;

const NavbarLogo = styled.div`
  background-image: url(${CoveeLogoText});
  width: 100%;
  height: 38px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Menu = styled.div`
  cursor: pointer;
  position: absolute;
  right: ${p => p.right};
  top: 13.5px;
  z-index: 99999;
  padding: 10px;
`;

const MenuBar = styled.div`
  background-color: white;
  width: 20px;
  height: 2.25px;
  margin: 0 0 3px 0;
`;

const OpenMenu = styled.div`
  transition: all 250ms ease-in-out;
  padding: 11px 0;
  background-color: #000;
  position: fixed;
  width: ${props => (props.open ? '100%' : '0')};
  height: ${props => (props.open ? 'auto' : '0')};
  z-index: 20;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
`;

const BigLinksWrapper = styled.div`
  margin: 35px 0 100px 0;
  text-align: center;
`;
const BigMenuItem = styled.div`
  color: white;
  padding: 50px 0;
  border-top: solid 0.05px #191919;
  border-bottom: solid 0.25px #191919;
  font-size: 32px;
`;

class BurgerMenu extends Component {
  // static propTypes = {
  // 	name: React.PropTypes.string,
  // };

  render() {
    const { ui, toggleBurgerMenu } = this.props;

    if (ui.isBurgerMenuOpen) {
      return (
        <OpenMenu height={ui.isBurgerMenuOpen} open={ui.isBurgerMenuOpen}>
          <NavLink onClick={() => toggleBurgerMenu()} to="/">
            <LogoWrapper>
              <NavbarLogo />
            </LogoWrapper>
          </NavLink>
          <Menu right="17px" onClick={() => toggleBurgerMenu()}>
            <MenuBar />
            <MenuBar />
            <MenuBar />
          </Menu>

          <Fade>
            <BigLinksWrapper onClick={() => toggleBurgerMenu()}>
              <NavLink to="/">
                <BigMenuItem>Home</BigMenuItem>
              </NavLink>
              {/*
                <NavLink to="/about">
                  <BigMenuItem>About</BigMenuItem>
                </NavLink>
              */}
            </BigLinksWrapper>
          </Fade>

          <Footer />
        </OpenMenu>
      );
    } else {
      return (
        <Wrapper open={ui.isBurgerMenuOpen}>
          <NavLink to="/">
            <LogoWrapper>
              <NavbarLogo />
            </LogoWrapper>
          </NavLink>
          <Menu right="25px" onClick={() => toggleBurgerMenu()}>
            <MenuBar />
            <MenuBar />
            <MenuBar />
          </Menu>
        </Wrapper>
      );
    }
  }
}

const mapStateToProps = ({ ui }) => ({ ui });
export default connect(mapStateToProps, { toggleBurgerMenu })(BurgerMenu);
