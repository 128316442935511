import React, { Component, Fragment } from 'react';
import styled from 'styled-components'
import theme from 'styled-theming';
import { Fade } from 'react-reveal';
import { NavLink } from 'react-router-dom';
// import { SignupForNewsletter, BurgerMenu, Button } from './index';

import ImgTelegram from '../assets/icons/telegram.svg';
import ImgLogo from '../assets/coveelogotext.svg';

const navbarColor = theme('mode', {
  dark: 'rgb(27, 1, 42)',
  light: '#fff',
});

const NavbarWrapper = styled.nav`
  height: 40px;
  padding: 15px 20px;
  background-color: ${navbarColor};
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;
  display: flex;
  font-weight: 100;
  @media screen and (max-width: 1035px) {
    height: auto;
    flex-direction: column;
  }
  @media screen and (max-width: 840px) {
    opacity: 0;
    display: none;
  }
`;

const LogoWrapper = styled.div`
  width: 200px;
  display: inline-block;
  padding: 0 0 10px 0;
  position: relative;
  top: -5px;
  @media screen and (max-width: 1035px) {
    width: 100%;
    margin: 10px 0 25px 0;
  }
`;

const NavbarLogo = styled.div`
  background-image: url(${ImgLogo});
  width: 100%;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 1035px) {
    width: 200px;
    margin: auto;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  text-align: right;
  transition: all 255ms ease-in-out;
  margin-right: 60px;
  @media screen and (max-width: 1035px) {
    position: relative;
    z-index: 9;
    text-align: center;
  }
  @media screen and (max-width: 840px) {
    display: flex;
    flex-direction: column;
  }
  > {
    &:hover {
      transition: all 255ms ease-in-out;
      color: #b2b2b2;
    }
  }
`;

const ExternalLink = styled.a`
  padding-bottom: 14px;
  padding-right: 45px;
  color: white;
  opacity: 1;
  line-height: 17px;
  letter-spacing: 0.03px;
  position: relative;
  transition: all 175ms ease-in-out;
  font-weight: ${p => p.fw ? p.fw : 'inherit'};
  @media screen and (max-width: 620px) {
    padding-right: 0;
  }
  &:before {
    content: '';
    position: absolute;
    background-image: ${props =>
      props.imagesrc ? `url(${props.imagesrc})` : null};
    width: 13px;
    height: 10px;
    top: 4.5px;
    left: -21.5px;
    transition: all 175ms ease-in-out;
    @media screen and (max-width: 840px) {
      margin: -1px 0 0 -24px;
      left: auto;
    }
  }
  &:after {
    position: absolute;
    left: 50%;
    content: '';
    height: 3px;
    background: #bc00ff;
    transition: all 0.2s linear;
    width: 0;
    bottom: -5px;
  }
  &:hover {
    color: white;
    opacity: 1;
    transition: all 175ms ease-in-out;
    filter: contrast(100%);
    &:after {
      width: ${props => props.hoverlinewidth};
      left: 0;
      bottom: 5px;
    }
    &:before {
      transition: all 175ms ease-in-out;
      transform: rotate(-2.5deg);
    }
  }
`;

const activeClassName = 'nav-item-active';

const NavItem = styled(NavLink).attrs(props => ({
  activeClassName: 'nav-item-active',
}))`
  padding-bottom: 14px;
  padding-right: 45px;
  color: white;
  opacity: 1;
  line-height: 17px;
  letter-spacing: 0.03px;
  position: relative;
  transition: all 175ms ease-in-out;
  @media screen and (max-width: 620px) {
    padding-right: 0;
  }
  &:before {
    content: '';
    position: absolute;
    background-image: ${props =>
      props.imagesrc ? `url(${props.imagesrc})` : null};
    width: 13px;
    height: 10px;
    top: 4.5px;
    left: -21.5px;
    transition: all 175ms ease-in-out;
    @media screen and (max-width: 840px) {
      margin: -1px 0 0 -24px;
      left: auto;
    }
  }
  &:after {
    position: absolute;
    left: 50%;
    content: '';
    height: 3px;
    background: #bc00ff;
    transition: all 0.2s linear;
    width: 0;
    bottom: -5px;
  }
  &:hover {
    color: white;
    opacity: 1;
    transition: all 175ms ease-in-out;
    filter: contrast(100%);
    &:after {
      width: ${props => props.hoverlinewidth};
      left: 0;
      bottom: 5px;
    }
    &:before {
      transition: all 175ms ease-in-out;
      transform: rotate(-2.5deg);
    }
  }
`;

export default class Navbar extends Component {
  render() {
    return (
      <Fragment>
        <NavbarWrapper>
          <NavLink to="/">
            <Fade>
              <LogoWrapper>
                <img src={`${ImgLogo}`} alt="covee logo"/>
              </LogoWrapper>
            </Fade>
          </NavLink>
          <TextWrapper>
            <ExternalLink
              href="#whitepaper"
              hoverlinewidth="85px" fw="400">
              White Paper
            </ExternalLink>
            {/*
              <NavItem hoverlinewidth="47px" to="/about">
                About
              </NavItem>
            */}
            {/* <ExternalLink */}
            {/*   href="https://t.me/coveenetwork" */}
            {/*   target="_blank" */}
            {/*   hoverlinewidth="69px" */}
            {/*   imagesrc={`${ImgTelegram}`} > */}
            {/*   Telegram */}
            {/* </ExternalLink> */}
            {/*
              <SignupForNewsletter
                type="alpha"
                padding="11px 59px"
                display="inline-block"
                margin="0 20px"
                paddingTablet="12px 20px"
                paddingMobile="12px 23px"
                buttonBorder="none"
                buttonColor="white"
                backgroundColor="rgb(188, 0, 255)"
                mailchimpUrl="https://network.us17.list-manage.com/subscribe/post?u=ba8e8ae977e232007b05c4f52&id=f7c258cb3f">
                Join the Beta
              </SignupForNewsletter>
            */}
            {/*
              <Link to="/getreward">
                <Button
                    padding="11px 59px"
                    display="inline-block"
                    margin="0 20px"
                    paddingTablet="12px 20px"
                    paddingMobile="12px 23px"
                    border="none"
                    color="white"
                    backgroundColor="rgb(188, 0, 255);"
                >
                    Join the Airdrop
                </Button>
              </Link>
           */}
          </TextWrapper>
        </NavbarWrapper>
      </Fragment>
    );
  }
}
