import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components'
import { connect } from 'react-redux';
import ModalVideo from 'react-modal-video';

import { toggleVideo_2 } from './../../redux/UI';

import WhitepaperImage from '../../assets/backgrounds/white-paper-image.svg';
import WhitepaperIcon from '../../assets/icons/icon-pdf.svg';
import GithubIcon from '../../assets/icons/icon-github.svg';
import YoutubeIcon from '../../assets/icons/icon-youtube.svg';

const Wrapper = styled.div`
  background: white;
  height: 480px;
  width: 100%;
  display: flex;
  padding: 5% 0;

  @media screen and (max-width: 1140px) {
    height: auto;
    flex-direction: column;
    padding: 2.5% 0 5% 0;
  }
`;

const TextWrapper = styled.div`
  margin: 40px 0;
  width: auto;
  padding: 0 0 0 45px;

  @media screen and (min-width: 1560px) {
    padding 0 0 0 125px;
  }

  @media screen and (max-width: 1140px) {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 0 20px;
    margin: 20px 0 35px 0;
  }
`;
const Header = styled.h1`
  font-weight: 400;

  @media screen and (max-width: 1140px) {
    padding-top: 35px;
  }
`;

const Paragraph = styled.p`
  width: 607px;
  letter-spacing: 0.03px;
  line-height: 20px;
  opacity: 0.6;
  font-size: 14px;
  color: #000;
  @media screen and (max-width: 720px) {
    width: 90%;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  margin: 20px 0;

  @media screen and (max-width: 1140px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 30px 0 0 0;
    height: 325px;
  }
`;

const Image = styled.div`
  background-image: url(${WhitepaperImage});
  width: 100%;
  height: 480px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media screen and (max-width: 725px) {
    height: 325px;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  margin: 28px 0 0 0;

  @media screen and (max-width: 725px) {
    flex-direction: column;
  }
`;

const Text = styled.div`
  background-color: ${p => p.bgc};
  color: ${p => p.color};
  padding: 10px;
  border-radius: 3px;
  transition: all 170ms ease-in-out;
  border: 2px solid transparent;
  display: inline-block;
  overflow: none;
  cursor: pointer;
  &:hover {
    transition: all 170ms ease-in-out;
  }
`;

const Icon = styled.div`
  width: 14px;
  height: 14px;
  background-image: url(${p => p.src});
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  top: ${p => (p.pos ? p.pos : '2.5px')};
  margin: 0 5px;
`;

class WhitepaperView extends Component {
  render() {
    const { toggleVideo_2, ui } = this.props;

    return (
      <section id="whitepaper" style={{ display: 'flex' }}>
        <Wrapper>
          <TextWrapper>
            <Header>Covee Protocol</Header>
            <Paragraph>
              The Covee Protocol is embedded in an Ethereum-based smart
              contract.
            </Paragraph>
            <Paragraph>
              The Protocol is based on a unique incentive and mechanism design
              and enforced by Covee’s native crypto token.
            </Paragraph>
            <Paragraph>
              Our programmable incentives, based on Blockchain technology,
              enable human collaboration that is globally scalable without the
              need for third-party intermediation such as corporate structures
              and legal agreements.
            </Paragraph>
            <Paragraph>
              Collaborative governance can therefore be self-organized,
              decentralized and borderless.
            </Paragraph>
            <ButtonSection>
              <a
                href="covee-whitepaper.pdf"
                target="_blank">
                <Text bgc="rgb(27, 1, 42)" color="white">
                  <Icon src={WhitepaperIcon} /> White Paper (PDF)
                </Text>
              </a>
              <a href="https://github.com/CoveeNetwork/protocol " target="_blank">
                <Text bgc="transparent" color="rgb(27, 1, 42)">
                  <Icon src={GithubIcon} /> Protocol source code
                </Text>
              </a>
              <div>
                <ModalVideo
                  channel="youtube"
                  videoId="qYbs8PzmKgs"
                  onClose={() => toggleVideo_2()}
                  isOpen={ui.isVideoOpen2}
                />
                <Text
                  onClick={() => toggleVideo_2()}
                  bgc="transparent"
                  color="rgb(27, 1, 42)">
                  <Icon src={YoutubeIcon} pos="4px" /> Explainer Video
                </Text>
              </div>
            </ButtonSection>
          </TextWrapper>
          <ImageWrapper>
            <Image />
          </ImageWrapper>
        </Wrapper>
      </section>
    );
  }
}

const mapStateToProps = ({ ui }) => ({ ui });

export default connect(mapStateToProps, { toggleVideo_2 })(WhitepaperView);
