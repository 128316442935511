import { keyframes } from 'styled-components';

export const MenuIn = keyframes`
  0% {
    transform: translate(0);
    height: 0;
  }
  100% {
    height: 250px;
  }
`;

export const MenuOut = keyframes`
  0% {
    transform: translate(0);
    height: 250px;
  }
  100% {
    transform: translate(500px);
    height: 0;
  }
`;

export const FadeInTop = keyframes`
	0% {
		opacity: 0;
		margin: -35px 0 0 0;
	}

	100% {
		opacity: 1;
		margin: 0;
	}

`;

export const FadeIn = keyframes`
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
`;
