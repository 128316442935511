import styled from 'styled-components'

export const PageSubTitle = styled.h3`
  font-family: 'Roboto mono';
  font-size: 14px;
  color: ${props => props.color};
  opacity: 0.7;
  margin: 0;
  width: ${p => (p.width ? p.width : '350px')};
  margin: ${p => (p.margin ? p.margin : '20px auto 35px auto')};
  font-weight: 100;
  text-align: ${p => (p.textAlign ? p.textAlign : 'center')};
  letter-spacing: 0.03px;
  line-height: 16px;

  @media screen and (max-width: 725px) {
    width: ${p => p.mobileWidth};
  }

  @media screen and (max-width: 550px) {
    width: 300px;
  }

  @media screen and (max-width: 375px) {
    width: 255px;
  }
`;
