import React from 'react';
import styled from 'styled-components'

import CoveeSquares from '../assets/backgrounds/covee-squares.svg';

const BackgroundWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 50px;
  background-image: url(${CoveeSquares});
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;

  @media screen and (max-width: 880px) {
    flex-direction: column;
    padding-bottom: 25px;
  }

  @media screen and (max-width: 550px) {
    background-image: ${props => props.backgroundMobile};
    background-color: ${props => props.backgroundColor};
    border-top: ${props => props.borderTopMobile};
    border-bottom: ${props => props.borderBottomMobile};
  }
`;

export default ({
  children,
  backgroundMobile,
  backgroundColor,
  borderTopMobile,
  borderBottomMobile,
}) => {
  return (
    <BackgroundWrapper
      backgroundColor={backgroundColor}
      backgroundMobile={backgroundMobile}
      borderTopMobile={borderTopMobile}
      borderBottomMobile={borderBottomMobile}
    >
      {children}
    </BackgroundWrapper>
  );
};
