import React, { Component, Fragment } from 'react';
import { Fade } from 'react-reveal';
import {
  DarkSquaredBackgroundWrapper,
  PageTitle,
  PageSubTitle,
  DarkBackgroundWrapper,
  UseCasesIconSet,
} from './../../components';
import WhitepaperView from './WhitepaperView';
import styled from 'styled-components'
import ReactPlayer from 'react-player';

import TripletsIcon from '../../assets/icons/triplets.svg';

const InnerWrapper = styled.div`
  margin: 55px 40px 45px 40px;

  @media screen and (max-width: 785px) {
    margin: 35px 0;
  }
`;

const VideoWrapper = styled.div`
  height: 400px;
  width: 550px;
  margin: 15px;

  @media screen and (max-width: 950px) {
    margin: 50px auto 25px auto;
    height: 253px;
    width: 386px;
  }

  @media screen and (max-width: 425px) {
    margin: 50px auto 25px auto;
    height: 175px;
    width: 299px;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

const Triplets = styled.div`
  background-image: url(${TripletsIcon});
  width: 386px;
  height: 253px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;

  @media screen and (max-width: 480px) {
    width: 300px;
  }
`;

export default class DarkSectionView extends Component {
  render() {
    return (
      <Fragment>
        <DarkSquaredBackgroundWrapper>
          <Fade>
            <PageTitle
              width="724px"
              paddingTop="100px"
              margin="auto"
              color="white">
              Removing the middleman and liberating knowledge workers from bureaucracy
            </PageTitle>
          </Fade>
          <OuterWrapper>
            <Fade>
              <VideoWrapper>
                <div className="player-wrapper">
                  <ReactPlayer
                    controls={true}
                    url="https://www.youtube.com/watch?v=c4bATjuwG3U"
                    width="100%"
                    className="react-player"
                  />
                </div>
              </VideoWrapper>
            </Fade>
            <InnerWrapper>
              <Fade>
                <Triplets />
              </Fade>
            </InnerWrapper>
          </OuterWrapper>
        </DarkSquaredBackgroundWrapper>
        <DarkBackgroundWrapper height="480px">
          <Fade>
            <PageTitle
              width="724px"
              paddingTop="65px"
              margin="auto"
              color="white">
              Use Cases
            </PageTitle>
            <PageSubTitle color="#A6A2AA">
              Apart from grading, decision finding, team analysis, ..., problems that can be solved by Covee knowledge workers are in
            </PageSubTitle>
            <UseCasesIconSet />
          </Fade>
        </DarkBackgroundWrapper>
        <WhitepaperView />

        <DarkSquaredBackgroundWrapper
          backgroundColor="#000"
          backgroundMobile="none"
          borderTopMobile="solid 0.5px rgb(27, 1, 42)"
          borderBottomMobile="solid 0.5px rgb(27, 1, 42)">
        </DarkSquaredBackgroundWrapper>
      </Fragment>
    );
  }
}
