import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

const Globals = () => createGlobalStyle`
	${styledNormalize}

	* {
		outline: none;
	}

	body, html {
	    font-family: 'Rubik', 'Circular Std Book', 'Roboto Mono', sans-serif;
		padding: 0;
		background-color: #000;
		height: 100%;
		width: 100%;
		position: relative;
		z-index: 50;
		font-smoothing: antialiased;
		-webkit-font-smoothing: antialiased;
		overflow-y: ${props => (props.isOpen ? 'hidden' : 'scroll')}
	}

	input {
	color: white;

		&::placeholder {
			color: #87818C;
		}

	}

	a {
		text-decoration: none;
		color: rgb(188, 0, 255);
	}

	ul {
		margin: 0;
		padding: 0;
	}

	hr {
		height: 1px;
		background-color: #E9E9E9;
		border: none;
		margin: 15px 0;
	}
`;

export default Globals;
