import React, { Component, Fragment } from "react";
import { Route, Switch, withRouter, BrowserRouter } from "react-router-dom";
import { connect, Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import { IndexContainer, AboutContainer } from "./pages";

import { Navbar, Footer } from "./components";
import { Globals } from "./styles";
import { SplashVideo } from "./styles";
import { configureStore } from './redux/Store';

const store = configureStore();

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <ThemeProvider theme={{ mode: "dark" }}>
          <BrowserRouter>
            <Provider store={store}>
              <div>
                <Navbar />
                <Switch>
                  <Route exact path="/" component={IndexContainer} />
                  {/*
              <Route path="/about" component={  } />
            */}
                </Switch>
                <Footer />
              </div>
            </Provider>
          </BrowserRouter>
        </ThemeProvider>
        <Globals />
        <SplashVideo />;
      </React.Fragment>
    );
  }
}

// export default withRouter(
//   connect(state => ({ card: state.ui.card }))(App)
// );
