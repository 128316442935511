import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';

import LandingView from './LandingView';
import InfoView from './InfoView';
import DarkSectionView from './DarkSectionView';
// import WhitepaperView from './WhitepaperView';

export default class IndexContainer extends Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  render() {
    return (
      <Fragment>
        <LandingView />
        <InfoView />
        <DarkSectionView />
      </Fragment>
    );
  }
}
