import React from 'react';
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const Header = styled.h1`
  font-size: 36px;
  font-weight: 400;
  font-family: 'Rubik';
  line-height: 20px;
`;

const TypedPink = styled.span`
  color: #bc00ff;
`;

const Span = styled.span`
  display: block;
  margin: 20px 0;
  @media screen and (max-width: 785px) {
    line-height: 45px;
  }
`;

export default ({ children }) => {
  return (
    <Wrapper>
      <Header>
        The <TypedPink>Trusted</TypedPink>
        <Span>Platform for Knowledge Workers.</Span>
      </Header>
    </Wrapper>
  );
};
