import React from 'react';
import styled from 'styled-components'

import ImgFinance from '../assets/icons/finance.svg';
import ImgGenomics from '../assets/icons/genomics.svg';
import ImgEngineering from '../assets/icons/engineering.svg';
import ImgBusinessAnalytics from '../assets/icons/BA.svg';
import ImgMachineLearning from '../assets/icons/machine_learning.svg';
import ImgEcommerce from '../assets/icons/ecommerce.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 75px 0;

  @media screen and (max-width: 900px) {
    display: inline-block;
    margin: 25px 0;
    width: 100%;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 25px;

  @media screen and (max-width: 900px) {
    width: 50%;
    display: inline-block;
    margin: auto;
    text-align: center;
  }
`;

const Icon = styled.div`
  background-image: url(${props => props.icon});
  width: 79px;
  height: 81px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;

  @media screen and (max-width: 525px) {
    width: 63px;
    height: 69px;
    background-size: contain;
  }
`;

const IconText = styled.h6`
  font-size: 18px;
  color: white;
  margin: 20px 0;
  font-weight: 100;

  @media screen and (max-width: 525px) {
    font-size: 12px;
  }
`;

export default () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Icon icon={ImgFinance} />
        <IconText>Finance</IconText>
      </InnerWrapper>
      <InnerWrapper>
        <Icon icon={ImgGenomics} />
        <IconText>Genomics</IconText>
      </InnerWrapper>
      <InnerWrapper>
        <Icon icon={ImgEngineering} />
        <IconText>Engineering</IconText>
      </InnerWrapper>
      <InnerWrapper>
        <Icon icon={ImgBusinessAnalytics} />
        <IconText>Business analytics</IconText>
      </InnerWrapper>
      <InnerWrapper>
        <Icon icon={ImgMachineLearning} />
        <IconText>Machine learning</IconText>
      </InnerWrapper>
      <InnerWrapper>
        <Icon icon={ImgEcommerce} />
        <IconText>E-commerce</IconText>
      </InnerWrapper>
    </Wrapper>
  );
};
