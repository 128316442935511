import React from 'react';
import styled from 'styled-components'

const DarkBackground = styled.div`
  background-color: #000;
  width: 100vw;
  height: ${props => props.height};

  @media screen and (max-width: 900px) {
    height: auto;
  }
`;

export default ({ children, height }) => {
  return <DarkBackground height={height}>{children}</DarkBackground>;
};
