import React from 'react';
import styled from 'styled-components'

const Wrapper = styled.div`
  width: ${props => props.width};
  padding-top: ${props => props.paddingTop};
  margin: ${props => props.margin};
  text-align: center;
  @media screen and (max-width: 720px) {
    width: auto;
  }
`;

const Header = styled.h1`
  font-size: 36px;
  font-weight: 400;
  font-family: 'Rubik';
  color: ${props => props.color};
  margin: ${props => props.headerMargin};

  @media screen and (max-width: 785px) {
    font-size: 30px;
    width: 425px;
    margin: auto;
  }

  @media screen and (max-width: 480px) {
    width: 250px;
    font-size: 20px;
  }
`;

export default ({
  children,
  color,
  width,
  paddingTop,
  margin,
  headerMargin,
}) => {
  return (
    <Wrapper width={width} paddingTop={paddingTop} margin={margin}>
      <Header headerMargin={headerMargin} color={color}>
        {children}
      </Header>
    </Wrapper>
  );
};
