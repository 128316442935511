import styled from 'styled-components'

import CoveeLogoText from '../assets/coveelogotext.svg';

const CoveeLogo = styled.div`
  background-image: url(${CoveeLogoText});
  width: ${p => p.width};
  height: ${p => p.height};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: ${p => p.margin};
`;

export default CoveeLogo;
