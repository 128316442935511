import { apiBuilder, callApi } from '../utils/apiHelpers';

const TOGGLE_BURGER_MENU = 'ui/menu/burger/toggle';
const CLOSE_BURGER_MENU = 'ui/menu/burger/close';
const TOGGLE_VIDEO = 'ui/video/toggle';
const TOGGLE_VIDEO2 = 'ui/video/toggle2';

const TOGGLE_SINGLE_CARD = 'ui/card/toggle_single_view';
const CLOSE_SINGLE_CARD = 'ui/card/close_single_card';
const PRESS_ESC_IN_SINGLE_CARD_VIEW = 'ui/card/press_esc';

const REGISTER_USER = apiBuilder('email', 'REGISTER_USER');

const initialState = {
  isViewingTeamMember: false,
  isBurgerMenuOpen: false,
  isVideoOpen: false,
  isVideoOpen2: false,
  card: {
    isOpen: false,
    id: '',
  },
  email: null,
};

const UI = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER.success: {
      return {
        ...state,
        email: 'success',
      };
    }
    case REGISTER_USER.failure: {
      return {
        ...state,
        email: 'error',
      };
    }
    case REGISTER_USER.request: {
      return {
        ...state,
        email: null,
      };
    }
    case TOGGLE_BURGER_MENU: {
      return {
        ...state,
        isBurgerMenuOpen: !state.isBurgerMenuOpen,
      };
    }

    case CLOSE_BURGER_MENU: {
      return {
        ...state,
        isBurgerMenuOpen: false,
      };
    }

    case TOGGLE_VIDEO: {
      return {
        ...state,
        isVideoOpen: !state.isVideoOpen,
      };
    }

    case TOGGLE_VIDEO2: {
      return {
        ...state,
        isVideoOpen2: !state.isVideoOpen2,
      };
    }

    case TOGGLE_SINGLE_CARD: {
      return {
        ...state,
        card: {
          isOpen: !state.card.isOpen,
          id: action.payload,
        },
      };
    }

    case CLOSE_SINGLE_CARD:
    case PRESS_ESC_IN_SINGLE_CARD_VIEW:
      return {
        ...state,
        card: {
          isOpen: !state.card.isOpen,
          id: '',
        },
      };

    default: {
      return state;
    }
  }
};

export function toggleBurgerMenu() {
  return { type: TOGGLE_BURGER_MENU };
}

export function closeBurgerMenu() {
  return { type: CLOSE_BURGER_MENU };
}
export function toggleVideo() {
  return { type: TOGGLE_VIDEO };
}

export function toggleVideo_2() {
  return { type: TOGGLE_VIDEO2 };
}

export function openCard(id) {
  console.log(window);
  return { type: TOGGLE_SINGLE_CARD, payload: id };
}

export function closeCard() {
  return { type: CLOSE_SINGLE_CARD };
}

export function pressEsc(e) {
  return dispatch => {
    if (e.keyCode === 27) {
      dispatch({ type: PRESS_ESC_IN_SINGLE_CARD_VIEW });
    }
  };
}

export const registerEmail = (email, type) =>
  callApi({
    endpoint: '/api/register-email',
    method: 'POST',
    json: {
      email,
      status: 'pending',
      type,
    },
    type: REGISTER_USER,
  });

export default UI;
