import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components'

import {
  PartnersSection,
  PartnerLogo,
  LandingText,
  FeaturedLogo,
} from './../../components';

import LandingViewBackgroundImage from '../../assets/wallpaper.svg';
// import MobileLandingBackground from '../../assets/images/main_background_mobile.svg';
import TechCrunch from '../../assets/images/partners/techcrunch.png';
import CoinNews from '../../assets/images/partners/coinnews.png';
import LocalGlobe from '../../assets/images/partners/localglobe.png';
import Pehub from '../../assets/images/partners/pehub.png';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(27, 1, 42);
  position: relative;
`;

const BackgroundImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background-image: url(${LandingViewBackgroundImage});
  width: 100%;
  height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: inherit;

  @media screen and (min-width: 2000px) {
    height: 1205px;
  }

  @media screen and (min-width: 1440px) and (max-width: 1999px) {
    height: 900px;
  }

  @media screen and (max-width: 1140px) {
    background-size: cover;
    height: 900px;
  }

  @media screen and (max-width: 725px) {
    height: 700px;
    padding: 0;
    background-size: cover;
  }

  @media screen and (max-width: 480px) {
    height: 640px;
    background-position: -286px -56px;
  }
`;

const LinkAnchor = styled.a`
  width: 10%;
  margin: 0 1%;
`;

class LandingView extends PureComponent {
  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  render() {
    return (
      <Wrapper>
        <section id="main">
          <BackgroundImage>
            <LandingText />
            <PartnersSection>
              <LinkAnchor href="https://medium.com/localglobe-notes/our-investment-in-covee-f4ff06d943c8">
                <PartnerLogo
                  src={LocalGlobe}
                  height="95px"
                  width="100px"
                  objectFit="contain"
                />
              </LinkAnchor>
              <LinkAnchor href="https://techcrunch.com/2018/04/30/covee-uses-blockchain-to-allow-experts-worldwide-to-collaborate/">
                <PartnerLogo
                  src={TechCrunch}
                  height="95px"
                  width="100px"
                  objectFit="cover"
                />
              </LinkAnchor>
              <FeaturedLogo>Featured in the media</FeaturedLogo>
              <LinkAnchor href="https://coinnewstelegraph.com/covee-uses-blockchain-to-allow-experts-worldwide-to-collaborate/">
                <PartnerLogo src={CoinNews} width="75px" objectFit="contain" />
              </LinkAnchor>
              <LinkAnchor href="https://www.pehub.com/2018/05/localglobe-leads-seed-funding-round-covee/">
                <PartnerLogo src={Pehub} width="55px" objectFit="contain" />
              </LinkAnchor>
            </PartnersSection>
          </BackgroundImage>
        </section>
      </Wrapper>
    );
  }
}

export default LandingView;
