import React, { Component } from 'react';
import styled from 'styled-components'

const Time = styled.div`
	text-align: center;
	margin: 10px 0;
	font-size: 22px;
	padding: 10px;
	background-color: transparent;
`;

export default class Countdown extends Component {
	state = {
		countdown: 'Initializing...',
	};

	componentDidMount() {
		const comp = this;

		// Update the count down every 1 second
		setInterval(function() {
			const currentDate = Math.floor(new Date().getTime() / 1000);
			const targetDate = 1532178000;

			const diff = targetDate - currentDate;

			const d = Math.floor(diff / 86400);
			const dr = diff % 86400;
			const h = Math.floor(dr / 3600);
			const hr = dr % 3600;
			const m = Math.floor(hr / 60);
			const s = hr % 60;
			comp.setState({ countdown: 'Airdrop is over.' });

			// if (currentDate > targetDate) {
			//   comp.setState({ countdown: 'Airdrop is over.' });
			// } else {
			//   comp.setState({ countdown: `${d}d ${h}h ${m}m ${s}s` });
			// }
		}, 1000);
	}

	render() {
		return <Time>Time left: {this.state.countdown}</Time>;
	}
}
